export default class Page
{
    constructor()
    {
        
    } 

    boot()
    {

    }
}