import LoginPage from "./login/LoginPage.js";
import Page from "./Page.js";
import AdminHistoryListPage from "./admin/AdminHistoryListPage.js";
import AdminHistorySinglePage from "./admin/AdminHistorySinglePage.js";
import HistoryPage from "./history/HistoryPage.js";
import ProfilePage from "./user/ProfilePage.js";
import SubjectPage from "./subject/SubjectPage.js";

export default class Boot
{
    constructor()
    {
        Boot.Instance = this;
    }      

    boot()
    {
        this.pageId = PAGE_ID.toLowerCase();
        this.page = null;

        switch (this.pageId)
        {
            case 'adminhistorylist':
                this.page = new AdminHistoryListPage();
                break;
            case 'adminhistorysingle':
                this.page = new AdminHistorySinglePage();
                break;
            case 'history':
                this.page = new HistoryPage();
                break;
            case 'login':
                this.page = new LoginPage();
                break;
            case 'profile':
                this.page = new ProfilePage();
                break;
            case 'subject':
                this.page = new SubjectPage();
                break;
        }

        if (this.page)
        {
            this.page.boot();
        }
    }
}