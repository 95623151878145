import Page from "../Page.js";
import Ajax from "../utils/Ajax.js";
import Formatting from "../utils/Formatting.js";
import MD5 from "../utils/MD5.js";

export default class SubjectPage extends Page
{
    //------------------------------------------------------
    static get HISTORY_URL() { return "/history/{subject}/{id}"; };
    static get CREATE_EXAM_URL() { return "/ajax/create-exam"; };
    static get SUBMIT_RESULTS_URL() { return "/ajax/submit-results"; };
    static get IMG_PATH() { return "/includes/img/questions/{0}"; };
    static get PERIODIC() 
    { 
        return {
            "1":"1̅",
            "2":"2̅",
            "3":"3̅",
            "4":"4̅",
            "5":"5̅",
            "6":"6̅",
            "7":"7̅",
            "8":"8̅",
            "9":"9̅",
            "0":"0̅"
        }; 
    };
    //------------------------------------------------------

    constructor()
    {
        super();

        this.isLoading = false;
    }      

    boot()
    {
        super.boot();

        this.subject = SUBJECT;
        this.exam = [];
        this.answers = {};
        this.questionIndex = 0;
        this.time = 0;

        this.sections = {
            home: document.getElementById("home"),
            exam: document.getElementById("exam"),
            submit: document.getElementById("submit"),
            results: document.getElementById("results")
        };

        this.buttons = {
            start: document.getElementById("btnStart"),
            back: document.getElementById("btnBack"),
            next: document.getElementById("btnNext"),
            submit: document.getElementById("btnSubmit"),
            submitBack: document.getElementById("btnSubmitBack")
        };

        this.loaders = {
            start: document.getElementById("start-loader"),
            submit: document.getElementById("submit-loader")
        };

        this.container = {
            question: document.getElementById("question-container"),
            score: document.getElementById("score")
        };

        this.timer = {
            container: document.getElementById("timer"),
            value: document.getElementById("timer-value")
        };

        this.questionTemplate = document.getElementById("question-template");
        this.questionTemplate.parentNode.removeChild(this.questionTemplate);

        let elements = document.getElementsByClassName("history")[0].getElementsByClassName("history-row");
        for (let i = 0; i < elements.length; i++)
        {
            elements[i].addEventListener(
                "click",
                this.onHistoryClick.bind(
                    this,
                    elements[i].getAttribute("data-subject"),
                    elements[i].getAttribute("data-id")
                )
            );
        }

        this.buttons.start.addEventListener("click", this.onStartClick.bind(this));
        this.buttons.back.addEventListener("click", this.onBackClick.bind(this));
        this.buttons.next.addEventListener("click", this.onNextClick.bind(this));
        this.buttons.submit.addEventListener("click", this.onSubmitClick.bind(this));
        this.buttons.submitBack.addEventListener("click", this.onSubmitBackClick.bind(this));

	setInterval(function(){
	    Ajax.get("/ajax/ping", () => {}, () => {});
	}.bind(this), 5000);
    }

    startExam()
    {
        this.timer.container.classList.remove("hide");

        this.timerInterval = setInterval(()=>
        {
            this.time++;

            let str = "";
            let hours = Math.floor(this.time / 3600);
            if (hours > 0)
            {
                str += hours + "h";
            }

            let minutes = Math.floor((this.time - hours * 3600) / 60);
            if (minutes > 0 || hours > 0)
            {
                if (hours > 0 && minutes < 10)
                {
                    str += "0";
                }
                str += minutes + "m";
            }

            let seconds = this.time - (hours * 3600 + minutes * 60);
            if ((minutes > 0 || hours > 0) && seconds < 10)
            {
                str += "0";
            }
            str += seconds + "s";

            this.timer.value.innerHTML = str;

        }, 1000);

        for (let i = 0; i < this.exam.length; i++)
        {
            let question = this.questionTemplate.cloneNode(true);

            question.id = "question-" + this.exam[i].id;
            question.classList.add("hide");
            question.getElementsByClassName("title")[0].innerHTML = (i + 1) + "- " + Formatting.formatString(this.exam[i].label);
            
            let img = question.getElementsByClassName("img")[0];
            if (this.exam[i].img)
            {
                img.src = SubjectPage.IMG_PATH.replace("{0}", this.exam[i].img);
            }
            else
            {
                img.classList.add("hide");
            }

            let container = question.getElementsByClassName("choices")[0];
            
            let answerTemplate = container.getElementsByClassName("choice")[0];
            answerTemplate.parentNode.removeChild(answerTemplate);


            for (let j = 0; j < this.exam[i].answers.length; j++)
            {
                let answer = answerTemplate.cloneNode(true);
                answer.addEventListener("click", this.onAnswerClick.bind(this, this.exam[i].id, this.exam[i].answers[j].id));

                answer.id = "answer-" + this.exam[i].id + "-" + this.exam[i].answers[j].id;
                answer.getElementsByClassName("letter")[0].innerHTML = String.fromCharCode(65 + j) + ")";
                answer.getElementsByClassName("content")[0].innerHTML = Formatting.formatString(this.exam[i].answers[j].label);

                container.appendChild(answer);
            }

            this.container.question.appendChild(question);
        }

        document.getElementById("question-" + this.exam[this.questionIndex].id).classList.remove("hide");

        this.buttons.back.setAttribute("disabled", "disabled");
        this.buttons.next.setAttribute("disabled", "disabled");
    }

    //------------------------------------------------------
    //  EVENTS
    //------------------------------------------------------
    onHistoryClick(subject, resultId)
    {
        window.location.href = SubjectPage.HISTORY_URL
            .replace("{subject}", subject)
            .replace("{id}", resultId);
    }

    onStartClick()
    {
        if (!this.isLoading)
        {
            this.isLoading = true;

            this.buttons.start.classList.add("hide");
            this.loaders.start.classList.remove("hide");

            Ajax.post(
                SubjectPage.CREATE_EXAM_URL, 
                "subject=" + encodeURIComponent(this.subject), 
                (data) => 
                {
                    this.buttons.start.classList.remove("hide");
                    this.loaders.start.classList.add("hide");

                    this.isLoading = false;

                    if (!data.error)
                    {
                        this.exam = data.exam;
                        this.startExam();

                        this.sections.home.classList.add("hide");
                        this.sections.exam.classList.remove("hide");
                    }
                },
                () => 
                {
                    this.buttons.start.classList.remove("hide");
                    this.loaders.start.classList.add("hide");

                    this.isLoading = false;
                }
            );
        }
    }

    onBackClick()
    {
        if (this.questionIndex > 0)
        {
            this.questionIndex--;

            if (this.questionIndex < this.exam.length - 1)
            {
                document.getElementById("question-" + this.exam[this.questionIndex + 1].id).classList.add("hide");
            }
            document.getElementById("question-" + this.exam[this.questionIndex].id).classList.remove("hide");

            if (this.questionIndex == 0)
            {
                this.buttons.back.setAttribute("disabled", "disabled");
            }
            this.buttons.next.removeAttribute("disabled");
        }
    }

    onNextClick()
    {
        this.questionIndex++;
        if (this.questionIndex >= this.exam.length)
        {
            this.sections.exam.classList.add("hide");
            this.sections.submit.classList.remove("hide");
        }
        else
        {
            document.getElementById("question-" + this.exam[this.questionIndex - 1].id).classList.add("hide");
            document.getElementById("question-" + this.exam[this.questionIndex].id).classList.remove("hide");

            if (!this.answers[this.exam[this.questionIndex].id])
            {
                this.buttons.next.setAttribute("disabled", "disabled");
            }
            else
            {
                this.buttons.next.removeAttribute("disabled");
            }
            this.buttons.back.removeAttribute("disabled");
        }
    }

    onSubmitClick()
    {
        if (!this.isLoading)
        {
            this.isLoading = true;

            this.buttons.submit.classList.add("hide");
            this.buttons.submitBack.classList.add("hide");
            this.loaders.submit.classList.remove("hide");

            Ajax.post(
                SubjectPage.SUBMIT_RESULTS_URL, 
                "answers=" + encodeURIComponent(JSON.stringify(this.answers)) + "&time=" + this.time, 
                (data) => 
                {
                    this.buttons.submit.classList.remove("hide");
                    this.loaders.submit.classList.add("hide");

                    this.isLoading = false;

                    if (!data.error)
                    {
                        clearInterval(this.timerInterval);
                        this.container.score.innerHTML = Math.round(data.score * 100) + "%";

                        this.sections.submit.classList.add("hide");
                        this.sections.results.classList.remove("hide");
                    }
                },
                () => 
                {
                    this.buttons.submit.classList.remove("hide");
                    this.loaders.submit.classList.add("hide");

                    this.isLoading = false;
                }
            );
        }
    }

    onSubmitBackClick()
    {
        this.sections.submit.classList.add("hide");
        this.sections.exam.classList.remove("hide");
    }

    onAnswerClick(questionId, answerId)
    {
        let answer = document.getElementById("answer-" + questionId + "-" + answerId);

        let answers = answer.parentNode.getElementsByClassName("choice");
        for(let i = 0; i < answers.length; i++)
        {
            answers[i].classList.remove("selected");
        }
        answer.classList.add("selected");

        this.answers[questionId] = answerId;

        this.buttons.next.removeAttribute("disabled");
    }
}
