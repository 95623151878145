export default class Formatting
{
    static formatString(str) 
    {
        let newStr = str;
        let matches = Formatting.preg_match_all(/\{([A-Za-z0-9\ \,\(\)\+\-\*\/\<\>]*)\}/, str);

        if (matches.length > 0)
        {
            for (let i = 0; i < matches[1].length; i++)
            {
                let params = matches[1][i].split(',');
                //Fraction
                if (params[0] == 'f')
                {
                    let index = 1;
                    let fractionStr = "<span class='fraction'><span class='inside'>";

                    let rest = "";
                    rest += "<span class='content'>";
                    rest += "<span class='top'>" + params[index] + "</span>";
                    index++;
                    rest += "<span class='bottom'>" + params[index] + "</span>";
                    index++;

                    let full = "";
                    if (params.length >= 4)
                    {
                        full += "<span class='full'>" + params[index] + "</span>";
                        index++;
                    }

                    fractionStr += full + rest + "</span></span></span>";

                    newStr = newStr.replace(matches[0][i], fractionStr);
                }
                //Periodic number
                else if (params[0] == 'p')
                {
                    let table = SubjectPage.PERIODIC;
                    let periodicStr = "";
                    for (let j = 0; j < params[1].length; j++)
                    {
                        periodicStr += table[params[1][j]];
                    }
                    newStr = newStr.replace(matches[0][i], periodicStr);
                }
            }
        }

        return newStr;
    }

    static preg_match_all(regex, str)
    {
      return [...str.matchAll(new RegExp(regex, 'g'))].reduce((acc, group) => {
        group.filter((element) => typeof element === 'string').forEach((element, i) => {
          if (!acc[i]) acc[i] = [];
          acc[i].push(element);
        });

        return acc;
      }, []);
    }
}