import Page from "../Page.js";
import Ajax from "../utils/Ajax.js";
import MD5 from "../utils/MD5.js";

export default class ProfilePage extends Page
{
    //------------------------------------------------------
    static get SAVE_URL() { return "/ajax/save-profile"; };
    //------------------------------------------------------

    constructor()
    {
        super();
    }      

    boot()
    {
        this.isLoading = false;

        this.messages = {
            "success": document.getElementById("msgSuccess"),
            "allFields": document.getElementById("msgAllFields"),
            "passwordSame": document.getElementById("msgPasswordSame"),
            "passwordLength": document.getElementById("msgPasswordLength"),
            "general": document.getElementById("msgGeneral")
        };

        this.inputs = {
            "name": document.getElementById("txtName"),
            "password": document.getElementById("txtPassword"),
            "confirmation": document.getElementById("txtConfirmation")
        };

        this.loader = document.getElementById("loader");
        this.button = document.getElementById("btnSave");

        document.getElementById("frmProfile").addEventListener("submit", this.onFormSubmit.bind(this));
    }

    saveProfile()
    {
        let error = false;

        for (let key in this.messages)
        {
            this.messages[key].classList.add("hide");
        }

        let name = this.inputs.name.value;
        let pass = this.inputs.password.value;
        let confirm = this.inputs.confirmation.value;
    
        if (name == '' || (pass == '' && confirm != ''))
        {
            error = true;
            this.messages.allFields.classList.remove("hide");
        }
        else if (pass != confirm)
        {
            error = true;
            this.messages.passwordSame.classList.remove("hide");
        }
        else if (pass.length > 0 && pass.length < 6)
        {
            error = true;
            this.messages.passwordLength.classList.remove("hide");
        }

        if (!error)
        {
            if (pass != '')
            {
                pass = MD5.hash(pass);
            }

            let args = "name=" + encodeURIComponent(name);
            if (pass != '')
            {
                args += "&password=" + pass;
            }

            this.button.classList.add("hide");
            this.loader.classList.remove("hide");

            Ajax.post(
                ProfilePage.SAVE_URL, 
                args, 
                this.onSaveCallback.bind(this), 
                () => {
                    this.button.classList.remove("hide");
                    this.loader.classList.add("hide");
                    this.messages.general.classList.remove("hide");

                    this.isLoading = false;
                }
            );
        }
    }

    //------------------------------------------------------
    //  EVENTS
    //------------------------------------------------------
    onFormSubmit(e)
    {
        e.preventDefault();

        if (!this.isLoading)
        {
            this.saveProfile();
        }

        return false;
    }

    onSaveCallback(data)
    {
        this.button.classList.remove("hide");
        this.loader.classList.add("hide");
        this.isLoading = false;

        if (data.success)
        {
            this.messages.success.classList.remove("hide");
        }
        else
        {
            this.messages.general.classList.remove("hide");
        }
    }
}