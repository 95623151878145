import Page from "../Page.js";
import Formatting from "../utils/Formatting.js";

export default class AdminHistoryListPage extends Page
{
    //------------------------------------------------------
    static get HISTORY_URL() { return "/admin/history/{subject}/{id}"; };
    //------------------------------------------------------

    constructor()
    {
        super();
    }      

    boot()
    {
        let elements = document.getElementsByClassName("history-row");
        for (let i = 0; i < elements.length; i++)
        {
            elements[i].addEventListener(
                "click",
                this.onHistoryClick.bind(
                    this,
                    elements[i].getAttribute("data-subject"),
                    elements[i].getAttribute("data-id")
                )
            );
        }
    }

    //------------------------------------------------------
    //  EVENTS
    //------------------------------------------------------
    onHistoryClick(subject, resultId)
    {
        window.location.href = AdminHistoryListPage.HISTORY_URL
            .replace("{subject}", subject)
            .replace("{id}", resultId);
    }
}