export default class Ajax
{
    /**
        Sends a GET call to a server side through an AJAX request

        @param string url               Url to call on the server side
        @param function callback        (Optional) Function to call when the request has finished successfully. Default is NULL
        @param function errorCallback   (Optional) Function to call if the request has failed. Default is NULL
        @param bool parseData           (Optional) If the resulting data of the request should be parsed before calling the callback. Default is TRUE
    */
    static get (url, callback = null, errorCallback = null, parseData = true)
    {
        let req = new XMLHttpRequest();
         
        Ajax.bindEvents(req, callback, errorCallback, parseData);
        req.open('GET', url, true);
        req.setRequestHeader("Accept","application/json");
        req.send();
    }

    /**
        Sends a POST call to a server side through an AJAX request

        @param string url               Url to call on the server side
        @param string parametersString  String containing the formatted parameters ready to be sent to the server side
        @param function callback        (Optional) Function to call when the request has finished successfully. Default is NULL
        @param function errorCallback   (Optional) Function to call if the request has failed. Default is NULL
        @param bool parseData           (Optional) If the resulting data of the request should be parsed as JSON before calling the callback. Default is TRUE
    */
    static post (url, parametersString, callback = null, errorCallback = null, parseData = true)
    {
        let req = new XMLHttpRequest();
        
        Ajax.bindEvents(req, callback, errorCallback, parseData);
        req.open('POST', url, true);
        req.setRequestHeader("Accept","application/json");

        if (parametersString)
        {
            req.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            req.send(parametersString);
        }
        else
        {
            req.send();
        }
    }

    static bindEvents(req, callback, errorCallback, parseData)
    {
        req.onreadystatechange = function()
        {
            if (this.readyState == 4)
            {
                if (this.status == 200)
                {
                    if (this.responseURL.substr(-9) == "/security")
                    {
                        window.location.reload();
                    }
                    else
                    {
                        if (callback)
                        {
                            let data = this.responseText;
                            if (data && parseData)
                            {
                                try
                                {
                                    data = JSON.parse(data);
                                } 
                                catch (ex) { }
                            }

                            callback(data);
                        }
                    }
                }
                else if (errorCallback) 
                {
                    errorCallback();
                }
            }
        };
    }
}