import Page from "../Page.js";
import Formatting from "../utils/Formatting.js";

export default class HistoryPage extends Page
{
    constructor()
    {
        super();
    }      

    boot()
    {
        let elements = document.getElementsByClassName("format");
        for (let i = 0; i < elements.length; i++)
        {
            elements[i].innerHTML = Formatting.formatString(elements[i].innerHTML);
        }
    }
}