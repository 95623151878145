import Page from "../Page.js";
import Ajax from "../utils/Ajax.js";
import MD5 from "../utils/MD5.js";

export default class LoginPage extends Page
{
    //------------------------------------------------------
    static get LOGIN_URL() { return "/ajax/login"; };
    //------------------------------------------------------

    constructor()
    {
        super();

        this.isLoading = false;
    }      

    boot()
    {
        super.boot();
        
        this.texts = {
            user: document.getElementById("username"),
            pass: document.getElementById("passwordInput")
        };

        this.labels = {
            errors: {
                "fields": document.getElementById("error1"),
                "general": document.getElementById("errorgeneral")
            }
        };

        this.loginButton = document.getElementById("btnGo");
        this.loader = document.getElementById("loader");
        this.frmLogin = document.getElementById("frmLogin");

        this.frmLogin.addEventListener("submit", this.onFormSubmit.bind(this));
    }

    //------------------------------------------------------
    //  EVENTS
    //------------------------------------------------------
    onFormSubmit(e)
    {
        e.preventDefault();

        if (!this.isLoading)
        {
            for (let key in this.labels.errors)
            {
                this.labels.errors[key].classList.add("hide");
            }

            this.loginButton.classList.remove("hide");
            this.loader.classList.add("hide");

            let user = this.texts.user.value;
            let pass = this.texts.pass.value;

            if (user && pass)
            {
                this.isLoading = true;

                user = encodeURI(user);
                pass = MD5.hash(pass);

                this.loginButton.classList.add("hide");
                this.loader.classList.remove("hide");

                Ajax.post(LoginPage.LOGIN_URL, "username=" + user + "&password=" + pass, this.onLoginCallback.bind(this), () => {this.isLoading = false;});
            }
            else
            {
                this.labels.errors.fields.classList.remove("hide");
            }
        }

        return false;
    }

    onLoginCallback(response)
    {
        this.isLoading = false;
        if (response && response.success)
        {
            window.location.href = response.success;
        }
        else
        {
            this.loginButton.classList.remove("hide");
            this.loader.classList.add("hide");
            if (response && response.error)
            {
                this.labels.errors.general.classList.remove("hide");
                this.labels.errors.general.innerHTML = response.error;
            }
        }
    }
}